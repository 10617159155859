import { API_BASE_URL } from '../constants';
import { request } from './request';

export type Settings = {
  currentIndex: {
    name: string;
    numberOfChunks: number;
  };
};

export const fetchSettings = () => request<Settings>(`${API_BASE_URL}/settings`);

type UpdateSettingsBody = {
  indexName?: string;
};

export const updateSettings = (body: UpdateSettingsBody) =>
  request<Settings>(`${API_BASE_URL}/settings`, { method: 'POST', body });
