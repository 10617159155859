import { useQuery, useQueryClient } from '@tanstack/react-query';

import { fetchSettings } from '../../api/settings';
import { QueryKey } from '../../constants';

const getQueryKey = () => [QueryKey.FetchSettings];

export const useInvalidateFetchSettings = () => {
  const queryClient = useQueryClient();
  return () => queryClient.invalidateQueries({ queryKey: getQueryKey() });
};

export const useFetchSettings = () => {
  return useQuery({
    queryFn: fetchSettings,
    queryKey: getQueryKey(),
    refetchOnWindowFocus: 'always',
  });
};
