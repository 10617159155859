import { KeyboardArrowDown } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import * as React from 'react';

import { MessageChunk } from '../../api/messages';
import classes from './SourceDocument.module.scss';

type Props = {
  chunk: MessageChunk;
};

export const RelatedChunk: React.FC<Props> = ({ chunk }) => {
  const { text, sourceUrl, distance } = chunk;

  return (
    <Accordion>
      <AccordionSummary className={classes.summary} expandIcon={<KeyboardArrowDown />}>
        <span className={classes.score}>{((1 - distance) * 100).toFixed(0)}</span>
        {text}
      </AccordionSummary>
      <AccordionDetails className={classes.details}>
        {text}
        {sourceUrl && (
          <a href={sourceUrl} className={classes.url} target="_blank" rel="noopener noreferrer">
            {sourceUrl}
          </a>
        )}
      </AccordionDetails>
    </Accordion>
  );
};
