import * as React from 'react';

import { useCreateMessagePending } from '../../hooks/messages/useCreateMessage';
import { useAppSelector } from '../../redux/hooks';
import { getMessages } from '../../redux/selectors';
import { Message } from './Message';
import { MessageInput } from './MessageInput';
import classes from './Messages.module.scss';

export const Messages: React.FC = () => {
  const ref = React.useRef<HTMLDivElement | null>(null);
  const [focusedMessageId, setFocusedMessageId] = React.useState<number | null>(null);

  const createMessagePending = useCreateMessagePending();

  const messages = useAppSelector(getMessages);

  return (
    <div className={classes.root} ref={ref}>
      <Message
        type={'model'}
        text={'Cześć! Jak mogę Ci pomóc?'}
        focused={focusedMessageId === null}
      />
      {messages.map((message, index) => {
        const focused = focusedMessageId === message.id || (!focusedMessageId && index === 0);
        const onFocus = () => setFocusedMessageId(message.id);
        const sharedProps = { focused, onFocus };

        return (
          <React.Fragment key={message.id}>
            <Message text={message.text} type={'user'} {...sharedProps} />
            <Message
              text={
                message.failed
                  ? message.response ??
                    'Wystąpił błąd podczas wysyłania wiadomości. Przepraszamy! :('
                  : message.response
              }
              type={'model'}
              pending={createMessagePending && index === messages.length - 1}
              chunks={message.chunks}
              error={message.failed}
              {...sharedProps}
            />
          </React.Fragment>
        );
      })}
      <MessageInput />
    </div>
  );
};
