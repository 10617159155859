import { API_BASE_URL } from '../constants';
import { request } from './request';

type Chunk = {
  id: number;
  text: string;
  sourceUrl?: string;
};

export type MessageChunk = Chunk & {
  distance: number;
};

export type Message = {
  id: number;
  text: string;
  response?: string;
  failed: boolean;
  chunks: MessageChunk[];
};

export type CreateMessageBody = {
  text: string;
  indexName?: string;
  userId: string;
};

export const createMessage = (body: CreateMessageBody) =>
  request<Message>(`${API_BASE_URL}/messages`, {
    method: 'POST',
    body,
  });
