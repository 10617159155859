import clsx from 'clsx';
import * as React from 'react';

import classes from './Loading.module.scss';

type Props = {
  className?: string;
};

export const Loading: React.FC<Props> = ({ className }) => {
  return (
    <div className={clsx(classes.root, className)}>
      {[...Array(3)].map((_, index) => (
        <div key={index} />
      ))}
    </div>
  );
};
