import { stringify } from 'qs';
import { mergeRight } from 'ramda';

import { AppError, ErrorCode } from './errors';

type RequestOptions = Omit<RequestInit, 'headers' | 'body'> & {
  headers?: Record<string, string>;
  query?: Record<string, any>;
  body?: Record<string, any>;
};

function serializeQueryParams(query: Record<string, any>): string {
  return stringify(query, { arrayFormat: 'comma' });
}

export const request = async <T = unknown>(url: string, init: RequestOptions = {}): Promise<T> => {
  const queryParams = init.query ? `?${serializeQueryParams(init.query)}` : '';

  const response = await fetch(`${url}${queryParams}`, {
    ...init,
    body: init.body ? JSON.stringify(init.body) : undefined,
    headers: mergeRight(
      {
        'Content-Type': 'application/json',
      },
      init.headers || {}
    ),
  });
  let body: T;
  let text = '';

  try {
    text = await response.text();
    body = text ? JSON.parse(text) : {};
  } catch (e) {
    throw new AppError('Failed to deserialize response body to JSON.', {
      errorCode: ErrorCode.JsonDeserializationFailed,
      params: {
        rawValue: text,
      },
    });
  }

  if (response.status > 201) {
    const message =
      (body as any)?.error ||
      (body as any)?.message ||
      `Request failed with status code: ${response.status}`;
    throw new AppError(message, {
      status: response.status,
      errorCode: ErrorCode.UnhandledException,
      params: {
        response: body || text,
        json: Boolean(body),
      },
    });
  }

  return body as any;
};
