import * as React from 'react';

import { Chatbot } from './components/Chatbot/Chatbot';
import { Devtools } from './components/Devtools/Devtools';
import { FloatingActions } from './components/FloatingActions/FloatingActions';
import { useInitializeTheme } from './hooks/useInitializeTheme';

export const App: React.FC = () => {
  useInitializeTheme();

  return (
    <>
      <FloatingActions />
      <Chatbot />
      <Devtools />
    </>
  );
};
