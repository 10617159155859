export enum QueryKey {
  FetchSettings = 'FetchSettings',
}

export enum MutationKey {
  CreateMessage = 'CreateMessage',
}

export const IS_DEVELOPMENT = process.env.NODE_ENV === 'development';

export const API_BASE_URL = process.env.REACT_APP_API_URL || '/api/v1';

export const USER_ID_LS_KEY = 'userId';

export const TMP_MESSAGE_ID = -1;
