import * as React from 'react';

import { MessageChunk } from '../../api/messages';
import { RelatedChunk } from './RelatedChunk';
import classes from './SourceDocuments.module.scss';

const INITIAL_DISPLAYED_COUNT = 2;

type Props = {
  chunks: MessageChunk[];
};

export const RelatedChunks: React.FC<Props> = ({ chunks }) => {
  const [expanded, setExpanded] = React.useState(false);

  const totalChunks = chunks.length;
  const finalChunks = expanded ? chunks : chunks.slice(0, INITIAL_DISPLAYED_COUNT);

  return finalChunks?.length ? (
    <div className={classes.root}>
      <div className={classes.title}>Zródła z których została wygenerowana odpowiedź</div>
      <div>
        {finalChunks.map((chunk, index) => (
          <RelatedChunk chunk={chunk} key={index} />
        ))}
      </div>
      {!expanded && totalChunks > INITIAL_DISPLAYED_COUNT && (
        <button className={classes.more} onClick={() => setExpanded(true)}>
          Więcej
        </button>
      )}
    </div>
  ) : null;
};
