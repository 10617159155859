import { createSelector } from '@reduxjs/toolkit';
import { pipe } from 'ramda';

import { messagesAdapter } from './adapters';
import { RootState } from './types';

export const rootSelector = (state: RootState) => state.main;

export const { selectAll: getMessages } = messagesAdapter.getSelectors(
  pipe(rootSelector, (state) => state.messages)
);

export const getDevtoolsOpen = createSelector(rootSelector, (state) => state.devtoolsOpen);
