import { enqueueSnackbar } from 'notistack';

import { getCodeFromError } from './errors';

export const enqueueDefaultErrorSnackbar = (error: unknown) => {
  return enqueueSnackbar({
    message: `Error?! ${getCodeFromError(error)}`,
    variant: 'error',
  });
};
