import * as React from 'react';

import logo from '../../assets/logo.png';
import logoLight from '../../assets/logo-light.png';
import { useTheme } from '../../hooks/useTheme';
import classes from './Footer.module.scss';

const DSC_URL =
  'https://www.linkedin.com/redir/redirect?url=https%3A%2F%2Fdsc%2Epja%2Eedu%2Epl%2F&urlhash=u5eF&trk=about_website';

export const Footer: React.FC = () => {
  const theme = useTheme();

  return (
    <div className={classes.root}>
      <img src={theme === 'dark' ? logoLight : logo} alt={'PJATK Logo'} />
      <div className={classes.promo}>
        Made by <b>Data Science Club</b>
        <a href={DSC_URL} title={'PJATK · Data Science Club'}>
          Dołącz do nas!
        </a>
      </div>
    </div>
  );
};
