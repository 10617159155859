import './app.scss';
import 'dayjs/locale/pl';

import { Experimental_CssVarsProvider as CssVarsProvider } from '@mui/material';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import updateLocale from 'dayjs/plugin/updateLocale';
import { SnackbarProvider } from 'notistack';
import * as React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import { App } from './app';
import { persistor, store } from './redux/store';
import { theme } from './theme';

dayjs.locale('pl');
dayjs.extend(relativeTime);
dayjs.extend(updateLocale);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    },
  },
});

const root = createRoot(document.getElementById('app')!);

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <CssVarsProvider theme={theme}>
        <Provider store={store}>
          <PersistGate persistor={persistor}>
            <SnackbarProvider
              anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
              hideIconVariant
            >
              <App />
            </SnackbarProvider>
          </PersistGate>
        </Provider>
      </CssVarsProvider>
    </QueryClientProvider>
  </React.StrictMode>
);
