import { createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit';

import { CreateMessageBody, Message } from '../api/messages';
import { TMP_MESSAGE_ID } from '../constants';
import { messagesAdapter } from './adapters';

type State = {
  messages: EntityState<Message>;
  devtoolsOpen?: boolean;
};

const initialState: State = {
  messages: messagesAdapter.getInitialState(),
  devtoolsOpen: false,
};

const { reducer, actions } = createSlice({
  name: 'main',
  initialState,
  reducers: {
    createMessage: (state, action: PayloadAction<CreateMessageBody>) => {
      messagesAdapter.addOne(state.messages, {
        id: TMP_MESSAGE_ID,
        failed: false,
        chunks: [],
        ...action.payload,
      });
    },
    replaceMessage: (state, action: PayloadAction<Message>) => {
      messagesAdapter.removeOne(state.messages, TMP_MESSAGE_ID);
      messagesAdapter.upsertOne(state.messages, action);
    },
    closeDevtools(state) {
      state.devtoolsOpen = false;
    },
    toggleDevtoolsOpen(state) {
      state.devtoolsOpen = !state.devtoolsOpen;
    },
    reset(state) {
      Object.assign(state, initialState);
    },
  },
});

export { actions, reducer };
