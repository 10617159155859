import { Drawer } from '@mui/material';
import * as React from 'react';

import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { getDevtoolsOpen } from '../../redux/selectors';
import { actions } from '../../redux/slice';
import classes from './Devtools.module.scss';
import { SearchIndexField } from './SearchIndexField';

export const Devtools: React.FC = () => {
  const open = useAppSelector(getDevtoolsOpen);
  const dispatch = useAppDispatch();

  const onClose = () => dispatch(actions.closeDevtools());

  return (
    <Drawer
      open={open}
      onClose={onClose}
      anchor={'right'}
      transitionDuration={0}
      classes={{
        paper: classes.paper,
      }}
    >
      <div className={classes.content}>
        <div className={classes.title}>Settings</div>
        <SearchIndexField />
      </div>
    </Drawer>
  );
};
