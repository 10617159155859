import { useColorScheme } from '@mui/material';
import * as React from 'react';

import { Theme } from '../types';

export function setRootElementClassName(theme: Theme): void {
  const html = window.document.documentElement;

  const className = `${theme}-theme`;
  const previousTheme = theme === 'dark' ? 'light' : 'dark';

  html.classList.add(className);
  html.classList.remove(`${previousTheme}-theme`);
}

export const useInitializeTheme = () => {
  const { mode, systemMode } = useColorScheme();

  const theme = mode === 'system' ? systemMode : mode;

  React.useEffect(() => {
    setRootElementClassName(theme ?? 'light');
  }, [theme]);
};
