import { BugReportOutlined, HighlightOff } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import clsx from 'clsx';
import * as React from 'react';

import { IS_DEVELOPMENT } from '../../constants';
import { useFetchSettings } from '../../hooks/settings/useFetchSettings';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { getDevtoolsOpen } from '../../redux/selectors';
import { actions } from '../../redux/slice';
import classes from './DevtoolsAction.module.scss';

type Props = {
  className?: string;
};

export const DevtoolsAction: React.FC<Props> = ({ className }) => {
  const [displayed, setDisplayed] = React.useState(IS_DEVELOPMENT);

  const open = useAppSelector(getDevtoolsOpen);
  const dispatch = useAppDispatch();

  const { data: settings } = useFetchSettings();

  const toggleOpen = () => dispatch(actions.toggleDevtoolsOpen());

  React.useEffect(() => {
    const onKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'k' && e.ctrlKey) setDisplayed(true);
    };
    window.addEventListener('keydown', onKeyDown);
    return () => window.removeEventListener('keydown', onKeyDown);
  }, []);

  return displayed ? (
    <IconButton className={clsx(classes.root, className)} onClick={toggleOpen}>
      {open ? <HighlightOff /> : <BugReportOutlined />}
      {settings && (
        <div className={classes.indexInfo}>
          {`Index: ${settings.currentIndex.name}`}
          <br />
          {`Documents: ${settings.currentIndex.numberOfChunks}`}
        </div>
      )}
    </IconButton>
  ) : null;
};
