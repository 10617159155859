import { Avatar } from '@mui/material';
import clsx from 'clsx';
import * as React from 'react';
import { useInView } from 'react-intersection-observer';

import { MessageChunk } from '../../api/messages';
import chatbotAvatar from '../../assets/chatbot-avatar.png';
import chatbotAvatarLight from '../../assets/chatbot-avatar-light.png';
import { useTheme } from '../../hooks/useTheme';
import { Loading } from '../Loading/Loading';
import classes from './Message.module.scss';
import { RelatedChunks } from './RelatedChunks';

type Props = {
  text?: string;
  type: 'user' | 'model';
  error?: boolean;
  pending?: boolean;
  focused?: boolean;
  chunks?: MessageChunk[];
  onFocus?: () => void;
};

export const Message: React.FC<Props> = ({
  text,
  type,
  pending,
  focused,
  error,
  chunks,
  onFocus,
}) => {
  const theme = useTheme();

  const { ref } = useInView({
    rootMargin: '-50% 0% -50% 0%',
    threshold: 0,
    onChange: (inView: boolean) => {
      if (!inView) return;
      onFocus?.();
    },
  });

  return (
    <div
      ref={ref}
      className={clsx(classes.root, classes[type], {
        [classes.pending]: pending,
        [classes.focused]: focused,
        [classes.error]: error,
        [classes.command]: text?.startsWith('/'),
      })}
    >
      <div className={classes.inner}>
        {type === 'model' && (
          <div className={classes.sender}>
            <Avatar
              src={theme === 'dark' ? chatbotAvatarLight : chatbotAvatar}
              className={classes.icon}
            />
            {"PJATK's Experimental LLM"}
          </div>
        )}
        <div className={classes.content}>{pending ? <Loading /> : text}</div>
      </div>
      {chunks && <RelatedChunks chunks={chunks} />}
    </div>
  );
};
