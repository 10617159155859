import { useIsMutating, useMutation } from '@tanstack/react-query';

import { createMessage, CreateMessageBody } from '../../api/messages';
import { MutationKey, TMP_MESSAGE_ID } from '../../constants';
import { useAppDispatch } from '../../redux/hooks';
import { actions } from '../../redux/slice';
import { getCodeFromError } from '../../utils/errors';
import { useInvalidateFetchSettings } from '../settings/useFetchSettings';
import { useUserId } from '../useUserId';

export const useCreateMessage = () => {
  const userId = useUserId();
  const dispatch = useAppDispatch();

  const invalidateFetchSettings = useInvalidateFetchSettings();

  return useMutation({
    mutationFn: async (body: Omit<CreateMessageBody, 'userId'>) => {
      const finalBody = { ...body, userId };
      dispatch(actions.createMessage(finalBody));
      try {
        return await createMessage(finalBody);
      } catch (e) {
        dispatch(
          actions.replaceMessage({
            id: TMP_MESSAGE_ID,
            response: `Błąd podczas wysyłania wiadomości: ${getCodeFromError(e)}. Przeprszamy! :(`,
            failed: true,
            chunks: [],
            ...finalBody,
          })
        );
        throw e;
      }
    },
    mutationKey: [MutationKey.CreateMessage],
    onSuccess: async (data) => {
      dispatch(actions.replaceMessage(data));
      await invalidateFetchSettings();
    },
  });
};

export const useCreateMessagePending = () => {
  return !!useIsMutating({
    mutationKey: [MutationKey.CreateMessage],
  });
};
