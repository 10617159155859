import { TextField } from '@mui/material';
import * as React from 'react';

import { useFetchSettings } from '../../hooks/settings/useFetchSettings';
import { useUpdateSettings } from '../../hooks/settings/useUpdateSettings';

export const SearchIndexField: React.FC = () => {
  const [value, setValue] = React.useState<string | null>(null);

  const { data: settings } = useFetchSettings();
  const { mutateAsync: updateSettings } = useUpdateSettings();

  const onUpdate = async () => {
    if (value === null) return;
    const searchIndexName = value?.trim() || 'default';
    const result = await updateSettings({ indexName: searchIndexName });
    setValue(result.currentIndex.name);
  };

  return (
    <TextField
      label={'Search Index Name'}
      value={value ?? settings?.currentIndex.name ?? ''}
      onChange={(e) => setValue(e.target.value)}
      onBlur={onUpdate}
    />
  );
};
