import { experimental_extendTheme as extendTheme } from '@mui/material/styles';

export const theme = extendTheme({
  shadows: Array(25).fill('none') as never,
  typography: {
    fontFamily: 'var(--font-base)',
  },
  colorSchemes: {
    dark: {
      palette: {
        mode: 'dark',
        primary: {
          main: '#fff',
        },
        text: {
          primary: 'var(--primary-text-color)',
        },
      },
    },
    light: {
      palette: {
        mode: 'light',
        primary: {
          main: '#000',
        },
        text: {
          primary: 'var(--primary-text-color)',
        },
      },
    },
  },
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
    MuiPaper: {
      defaultProps: {
        elevation: 0,
      },
      styleOverrides: {
        root: {
          backgroundColor: 'var(--background-color)',
          backgroundImage: 'none',
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          maxWidth: '100%',
          margin: '0 !important',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: ({ theme }) => ({
          padding: theme.spacing(1.5),
        }),
      },
    },
    MuiInputBase: {
      styleOverrides: {
        adornedEnd: {
          paddingRight: 'var(--space-1) !important',
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: ({ theme }) => ({
          minHeight: 'unset !important',
          padding: theme.spacing(2, 0),
        }),
        content: {
          maxWidth: '100%',
          margin: '0 !important',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          display: 'block',
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          whiteSpace: 'pre-line',
        },
      },
    },
  },
});
