export enum ErrorCode {
  UnhandledException = 'UnhandledException',
  JsonDeserializationFailed = 'JsonDeserializationFailed',
}

export type ParamsByErrorCode = {
  [ErrorCode.UnhandledException]: {};
  [ErrorCode.JsonDeserializationFailed]: { rawValue: string };
};

export type ErrorExtraData<T extends ErrorCode> = {
  errorCode: T;
  status?: number;
} & (T extends keyof ParamsByErrorCode ? { params: ParamsByErrorCode[T] } : never);

export class AppError<T extends ErrorCode> extends Error {
  static fromJson(data: any) {
    const message = 'message' in data ? data.message : 'Unknown error. Something went wrong.';
    const errorCode = 'errorCode' in data ? data.errorCode : ErrorCode.UnhandledException;
    const params = 'params' in data ? data.params : undefined;

    return new AppError(message, { errorCode, params });
  }

  static is<S extends ErrorCode>(error: unknown, errorCode: S): error is AppError<S> {
    if (!(error instanceof AppError)) return false;
    return error.extra.errorCode === errorCode;
  }

  constructor(public readonly message: string, public readonly extra: ErrorExtraData<T>) {
    super(message);
    this.extra.status ||= 500;
  }
}
