import { Sync } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import * as React from 'react';

import { IS_DEVELOPMENT } from '../../constants';
import { useAppDispatch } from '../../redux/hooks';
import { actions } from '../../redux/slice';
import { ToggleThemeButton } from '../ToggleThemeButton/ToggleThemeButton';
import { DevtoolsAction } from './DevtoolsAction';
import classes from './FloatingActions.module.scss';

export const FloatingActions: React.FC = () => {
  const dispatch = useAppDispatch();

  const onReset = () => dispatch(actions.reset());

  return (
    <div className={classes.root}>
      <DevtoolsAction className={classes.button} />
      <ToggleThemeButton className={classes.button} />
      {IS_DEVELOPMENT && (
        <IconButton className={classes.button} onClick={onReset}>
          <Sync />
        </IconButton>
      )}
    </div>
  );
};
