import { ArrowUpward } from '@mui/icons-material';
import { IconButton, InputAdornment, InputBase } from '@mui/material';
import clsx from 'clsx';
import * as React from 'react';

import { useCreateMessage } from '../../hooks/messages/useCreateMessage';
import classes from './MessageInput.module.scss';

export const MessageInput: React.FC = () => {
  const [text, setText] = React.useState('');

  const { mutateAsync: createMessage, isPending } = useCreateMessage();

  const onCreateMessage = async () => {
    if (!text) return;
    await createMessage({ text: text });
    setText('');
  };

  const onSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    onCreateMessage();
  };

  const onKeyDown: React.KeyboardEventHandler<HTMLTextAreaElement> = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) e.preventDefault();
  };

  const onKeyUp: React.KeyboardEventHandler<HTMLTextAreaElement> = (e) => {
    if (e.key === 'Enter' && !e.shiftKey && text) onCreateMessage();
  };

  return !isPending ? (
    <form
      className={clsx(classes.root, { [classes.command]: text.startsWith('/') })}
      onSubmit={onSubmit}
    >
      <InputBase
        value={text}
        fullWidth
        multiline
        minRows={1}
        maxRows={Infinity}
        name={'message'}
        onChange={(e) => setText(e.target.value)}
        placeholder={'Twoja Wiadomość'}
        onKeyDown={onKeyDown}
        onKeyUp={onKeyUp}
        classes={{
          root: classes.inputRoot,
        }}
        endAdornment={
          <InputAdornment position={'end'}>
            <IconButton type={'submit'} className={classes.button}>
              <ArrowUpward />
            </IconButton>
          </InputAdornment>
        }
      />
    </form>
  ) : null;
};
