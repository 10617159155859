import { v4 } from 'uuid';

import { USER_ID_LS_KEY } from '../constants';

export const useUserId = () => {
  let userId = localStorage.getItem(USER_ID_LS_KEY);

  if (!userId) {
    userId = v4();
    localStorage.setItem(USER_ID_LS_KEY, userId);
  }

  return userId;
};
