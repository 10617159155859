import { useMutation } from '@tanstack/react-query';

import { updateSettings } from '../../api/settings';
import { enqueueDefaultErrorSnackbar } from '../../utils/snackbar';
import { useInvalidateFetchSettings } from './useFetchSettings';

export const useUpdateSettings = () => {
  const invalidateFetchSettings = useInvalidateFetchSettings();

  return useMutation({
    mutationFn: updateSettings,
    onError: enqueueDefaultErrorSnackbar,
    onSuccess: invalidateFetchSettings,
  });
};
