import { DarkModeOutlined, LightModeOutlined } from '@mui/icons-material';
import { ButtonProps, IconButton, useColorScheme } from '@mui/material';
import clsx from 'clsx';
import * as React from 'react';

import { useTheme } from '../../hooks/useTheme';
import classes from './ToggleThemeButton.module.scss';

type Props = Omit<ButtonProps, 'startIcon' | 'children' | 'onClick'>;

export const ToggleThemeButton: React.FC<Props> = ({ className, ...props }) => {
  const { setMode } = useColorScheme();
  const theme = useTheme();

  const onToggleTheme = () => {
    const nextTheme = theme === 'dark' ? 'light' : 'dark';
    setMode(nextTheme);
  };

  return (
    <IconButton onClick={onToggleTheme} className={clsx(classes.root, className)} {...props}>
      {theme === 'dark' ? <LightModeOutlined /> : <DarkModeOutlined />}
    </IconButton>
  );
};
