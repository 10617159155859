import * as React from 'react';

import classes from './Chatbot.module.scss';
import { Footer } from './Footer';
import { Messages } from './Messages';

export const Chatbot: React.FC = () => {
  return (
    <div className={classes.root}>
      <Messages />
      <Footer />
    </div>
  );
};
